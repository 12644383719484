.leaflet-container {
    height: 100vh;
    width: 100%;
}

.map-z-index{
    z-index: 10001;
}

.map-checkbox{
    left: 10px;
    bottom: 25px;
}

.map-location{
    right: 10px;
    bottom: 25px;
}

.map-redoSearch{
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}


.ReactModal__Overlay {
    z-index: 10001;
}


.allo{
    width: 50px;
    height: 50px;
}


.filterTab{
    height: 32px;
    width: 18px;
    position: absolute;
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
}


.search-input{
    width:  100%;
    font-size: 14px;
    word-wrap: break-word;
    line-height: 1em;
    outline: 0;
    white-space: normal;
    min-height: 2em;
    background: #fff;
    display: inline-block;
    padding: .5em 2em .5em 1em;
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .25rem;
}




@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}




@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}
@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}
@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}

.fadeLeft-enter {
    -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
}

.fadeLeft-enter.fadeLeft-enter-active {
    -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
}

.fadeLeft-exit {
    -webkit-animation-name: fadeOutLeft;
            animation-name: fadeOutLeft;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
}

.fadeLeft-exit.fadeLeft-exit-active {
    -webkit-animation-name: fadeOutLeft;
            animation-name: fadeOutLeft;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
}


.applied-height{
}

.filter-button{
    height: 40px;
    width: 50%;

}

/*input[type='checkbox'] {*/
    /*-webkit-appearance:none;*/
    /*width:15px;*/
    /*height:15px;*/
    /*background:white;*/
    /*border-radius:5px;*/
/*}*/


.border{
    border-radius: 5px;
    border: 1px solid #ecedee;
    /*position: absolute;*/
}

.fix{
    width: 100%;
    margin-top: 10px;
    -webkit-transform: translate3d(0px, 65px, 0px) !important;
            transform: translate3d(0px, 65px, 0px) !important;
}

input[type=search]{
    width: 100%;
    font-size: 16px;
    word-wrap: break-word;
    line-height: 1em;
    outline: 0;
    white-space: normal;
    min-height: 1.0em;
    background: #fff;
    display: inline-block;
    padding: .5em .5em .5em .5em;
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .30rem;
    transition: box-shadow .1s ease,width .1s ease;
    -webkit-transition: box-shadow .1s ease,width .1s ease;
}

.content{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.p-inherit{
    padding: inherit;
    margin: inherit;
}




